import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import "./Header.css"
import {BiMenuAltRight} from 'react-icons/bi'
const Header = () => {
    console.log(window.pageYOffset);
    const [menuOpened,setMenuOpened]=useState(false);
    const getMenuStyle=(menuOpened)=>{
        if(document.documentElement.clientWidth <= 800){
            console.log(document.documentElement.clientWidth)
            // return {}
            return{ right:  !menuOpened && "-100%"}
        }}
    const Menutrigger =()=>{
        console.log(menuOpened);
        setMenuOpened(!menuOpened);
    }
    const Handleresidence=(e)=>{
        e.preventDefault();
        window.scrollBy(0, 900);
        
    }
    const HandleValues=(e)=>{
        e.preventDefault();
        window.scrollBy(0, 1500);
        
    }
    const HandleContactus=(e)=>{
        e.preventDefault();
        window.scrollBy(0, 2150);
        
    }
    const HandleGetstarted=(e)=>{
        e.preventDefault();
        window.scrollBy(0, 2350);
        
    }
  return (
    <section className='h-wrapper'>
        <div className='flexCenter paddings innerWidth h-container'>
            <img src='./logo.png' alt='logo' width={100}/>
           <OutsideClickHandler
           onOutsideClick={(e)=>{
            
            
            setMenuOpened(false);
           }}
           >
           <div className='flexCenter h-menu' style={getMenuStyle(menuOpened)}>
                <a href='' onClick={Handleresidence}>Residencies</a>
                <a href='' onClick={HandleValues}>Our Value</a>
                <a href=''onClick={HandleContactus}>Contact Us</a>
                <a href='' onClick={HandleGetstarted}>Started</a>
                <button onClick={HandleGetstarted}className='button'>
                    <a href='/'>Contact</a>
                </button>
            </div>
            </OutsideClickHandler>
            <div className='menu-icon'>
            <BiMenuAltRight size={25} onClick={Menutrigger}/>
        <i class="fa-solid fa-bars"></i>
        </div>
        </div>
        
    </section>
  )
}

export default Header
