import React from "react";
import Header from "./components/header/Header";
import Hero from "./components/Hero/Hero";
import Companies from "./components/companies/Companies";
import { Residencies } from "./components/companies/Residencies/Residencies";
import Value from "./components/value/Value";
import Contact from "./components/Contact/Contact";
import GetStarted from "./components/GetStarted/GetStarted";
import Footer from "./components/Footer/Footer";
function App() {
  return (
    <>
    <div className="App">
     <Header/>
     <Hero/>
    </div>
    <Companies/>
    <Residencies/>
    <Value/>
    <Contact/>
    <GetStarted/>
    <Footer/>
    
    </>

  );
}

export default App;
