import React from 'react'
import { useState } from 'react';
import './Value.css'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './Value.css';
import data from '../../utils/accordion';
import { MdOutlineArrowDropDown } from 'react-icons/md';
const Value = () => {
    const [className, setClassName] = useState(null)
    return (
        <section className='v-wrapper'>
            <div className='paddings innerWidth flexCenter v-container'>
                <div className='v-container'>
                    <div className='image-container'>
                        <img src='./value.png' alt="" />
                    </div>
                    <div className='flexColStart v-right'>
                        <span className='orangeText'>Our Value</span>
                        <span className='primaryText'>Value We give to you</span>
                        <span className='secondaryText'>
                            We always ready to help by prividng the best service
                            <br />
                            We beleive a good blace to live can make your life better
                        </span>
                        <Accordion className='accordion' allowMultipleExpanded={false}
                            preExpanded={[0]}>
                            {
                                data.map((item, i) => {
                                    return (
                                        <AccordionItem className={`accordionItem ${className}`}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton className='flexCenter accordionbutton'>
                                                    <AccordionItemState>
                                                        {({ expanded }) => expanded ? setClassName('expanded') : setClassName('collapsed')}
                                                    </AccordionItemState>
                                                    <div className='flexCenter icon'>{item.icon}</div>
                                                    <span className='primaryText'>{item.heading}</span>
                                                    <div className='flexCenter icon'>
                                                        <MdOutlineArrowDropDown size={20} />
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className='secondaryText'>{item.detail}</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Value
