import React from 'react'
import './GetStarted.css'
const GetStarted = () => {
  return (
    <section className='g-wrapper'>
        <div className='paddings innerWidth g-container'>
            <div className='flexColCenter inner-container'>
                <span className='primaryText'>
                    Get Started with Homyz
                </span>
                <span className='secondaryText'>
                    Subscirte and find super attractive pirce quotes from us
                    <br/>
                    Find Your resisdence soon
                </span>
                <button className='getstarted-button button padding'>
                    <a href='mailto:jaguargagan@gmail.com'>Get Started</a>
                </button>
            </div>
        </div>
    </section>
  )
}

export default GetStarted
