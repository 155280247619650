import React from "react";
import "./Hero.css"
// import {HiLocationMarker} from 'react-icon/hi';
// import CountUp from 'react-counter';
import CountUp from 'react-countup';

const Hero = () => {
    return (
        <section className="hero-wrapper">
            <div class="paddings innerWidth flexCenter hero-container">
                <div className="flexColStart hero-left">
                    <div className="Headings">
                    <div className="hero-title">
                        <div className="orange-circle" />
                        <h1>Discover <br /> Most Suitable <br />Property</h1>
                    </div>
                    <div className="flexColStart hero-des">
                        <span className="secondaryText">Find a varity of propty that suit you very easilyl</span>
                        <span className="secondaryText">forget al difficulties in the finding of good property</span>
                    </div>
                </div>
                <div className="search-bar flexCenter">
                    <input type='text' />
                    <button className="button">Search bar</button>
                </div>

                <div className="flexCenter stats">
                    <div className="flexColCenter stat">
                        <span >
                            <CountUp start={88000} end={99000} duratin={4} />
                            <span>+</span>
                        </span>
                        <span className="secondaryText">Happy Customers</span>
                    </div>
                    <div className="flexColCenter stat">
                        <span>
                            <CountUp start={12000} end={15000} duratin={4} />
                            <span>+</span>
                        </span>
                        <span className="secondaryText">Premuim</span>
                    </div>
                    <div className="flexColCenter stat">
                        <span>
                            <CountUp start={40} end={500} duratin={4} />
                            <span>+</span>
                        </span>
                        <span className="secondaryText">Happy Customers</span>
                    </div>
                </div>
            </div>

            <div className="flexCenter hero-right">
                <div className="image-container">
                    <img src="./hero-image.png" alt="hero" />
                </div>
            </div>
        </div>
        </section >
    )
}

export default Hero
