import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/css'
import './Residencies.css'
// import data from '../../utils/slider.json';
import data from '../../../utils/slider.json';
import { sliderSetting } from '../../../utils/common';
export const Residencies = () => {
    const SwiperButton =()=>{
        const swiper =useSwiper();
        return(
            <div className='r-buttons flexCenter'>
                <button onClick={()=>swiper.slidePrev()}>&lt;</button>
                <button onClick={()=>swiper.slideNext()}>&gt;</button>
            </div>
            )}
    return (
        <section className='r-wrapper'>
            <div className='padding innerWidth r-container'></div>
            <div className='r-head flexColStart'>
                <span className='orangeText'>Best Choices</span>
                <span className='primaryText'>Popular Residencies</span>
            </div>
            <Swiper {...sliderSetting}>
            <SwiperButton/>
                {
                    data.map((card, i) => (
                        <SwiperSlide key={i}>
                            <div className='flexColStart r-card'>
                                <img src={card.image} alt="home" />
                                <span className='secondaryText r-price'></span>
                                <span style={{ color: "orange" }}>$</span>
                                <span className='primaryText'>{card.price}</span>
                                <span className='secondaryText'>{card.price}</span>
                                <span>{card.name}</span>
                            </div>
                        </SwiperSlide>
                    ))
                }
                
            </Swiper>
            
        </section>
    )
}
