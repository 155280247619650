import React from 'react';
import './Contact.css';
import { MdCall } from 'react-icons/md'
import { BsFillBubbleBottomCenter } from 'react-icons/hi';

const Contact = () => {
    return (
        <section className='c-wrapper'>
            <div className='paddings innerWidth flexCenter c-container'>
                {/* <div className='flexColStart c-left'>
                Left
            </div> */}
                <div className='c-right'>
                    <div className='image-container'>
                        <img src='./contact.jpg' alt='contact' />
                    </div>
                </div>
                <div className='flexColStart c-left'>
                    <span className='orangeText'>Our Contacts</span>
                    <span className='primaryText'>Easy to Contact us</span>
                    <span className='secondaryText'>We always reado to help by beeive a good place
                        to live can make your life better</span>
                    <div className='all-box'>
                        <div className='flexStart contactModes row'>
                            <div className='flexColStart row'>
                                <div className='flexColCenter Mode'>
                                    <div className='flexStart'>
                                        <div className='flexCenter icon'>
                                            <MdCall size={25} />
                                        </div>
                                        <div className='flexStart detail'>
                                            <span className='primaryText'>Call</span>
                                            <span className='secondaryText'>021 012 145 145</span>
                                        </div>
                                    </div>
                                    <div className='flexCenter button'>Chat Now</div>
                                </div>
                            </div>
                        </div>
                        <div className='flexColStart contactModes row'>
                            <div className='flexColStart row'>
                                <div className='flexColCenter Mode'>
                                    <div className='flexStart'>
                                        <div className='flexCenter icon'>
                                            <MdCall size={25} />
                                        </div>
                                        <div className='flexStart detail'>
                                            <span className='primaryText'>Chat</span>
                                            <span className='secondaryText'>445 512 455 175</span>
                                        </div>
                                    </div>
                                    <div className='flexCenter button'>Chat Now</div>
                                </div>
                            </div>
                        </div>
                        <div className='flexColStart contactModes row'>
                            <div className='flexColCenter Mode'>

                                <div className='flexStart'>
                                    <div className='flexCenter icon'>
                                        <MdCall size={25} />
                                    </div>

                                    <div className='flexColStart detail'>
                                        <span className='primaryText'>Message</span>
                                        <span className='secondaryText'>021 012 145 145</span>
                                    </div>
                                </div>
                                <div className='flexCenter button'>Message Now</div>
                            </div>
                        </div>
                        <div className='flexColStart contactModes row'>
                            <div className='flexColCenter Mode'>
                                <div className='flexStart'>
                                    <div className='flexCenter icon'>
                                        <MdCall size={25} />
                                    </div>
                                    <div className='flexColStart detail'>
                                        <span className='primaryText'>Video Call</span>
                                        <span className='secondaryText'>021 012 145 145</span>
                                    </div>
                                </div>
                                <div className='flexCenter button'>Video Call Now</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Contact
